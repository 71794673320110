@use '@angular/material' as mat;
@use '../../node_modules/matcha-theme/main.scss' as matcha;
@include mat.core();

//MATERIAL THEME - LIGHT
$kids-primary: mat.define-palette(mat.$yellow-palette, 500);
$kids-accent: mat.define-palette(mat.$light-blue-palette, A200, A100, A400);
$kids-typography: mat.define-typography-config(
	$font-family: 'Comic Sans MS',
);

$kids-theme: mat.define-light-theme(
	(
		color: (
			primary: $kids-primary,
			accent: $kids-accent,
		),
		typography: $kids-typography,
	)
);

.theme-kids {
	@include mat.all-component-themes($kids-theme);
}

//MATCHA THEME - LIGHT
// palette
$default-light-primary: matcha.palette(matcha.$blue-grey, A700, A400, 900); // Primary color
$default-light-accent: matcha.palette(matcha.$lime, 500, 200, 900); // Accent color
$default-light-warn: matcha.palette(matcha.$red, 900, 200, 900);
// typography
$matcha-default-typography: matcha.matcha-typography-config(
	$font-family: 'CircularStd, sans-serif, arial',
);
$mat-default-typography: mat.define-typography-config(
	$font-family: 'CircularStd, sans-serif, arial',
);
// theme
$matcha-default-theme: matcha.light-theme($default-light-primary, $default-light-accent, $default-light-warn);
$mat-default-theme: mat.define-light-theme(
	(
		color: (
			primary: $default-light-primary,
			accent: $default-light-accent,
			warn: $default-light-warn,
		),
		typography: $mat-default-typography,
	)
);

.theme-default-light {
	// Create an Angular Material theme from the $theme map
	@include mat.all-component-themes($mat-default-theme);
	@include matcha.matcha-components($matcha-default-theme);
	@include matcha.matcha-typography($matcha-default-typography);
}

//MATCHA THEME - DARK
// palette
$default-dark-primary: matcha.palette(matcha.$blue, 100, 50, 200); // Primary color
$default-dark-accent: matcha.palette(matcha.$lime, A400, A200, A700); // Accent color
$default-dark-warn: matcha.palette(matcha.$red, 200, 50, 300);
// typography
$matcha-default-typography: matcha.matcha-typography-config(
	$font-family: 'CircularStd, sans-serif, arial',
);
$mat-default-typography: mat.define-typography-config(
	$font-family: 'CircularStd, sans-serif, arial',
);
// theme
$matcha-default-theme: matcha.dark-theme($default-dark-primary, $default-dark-accent, $default-dark-warn);
$mat-default-theme: mat.define-dark-theme(
	(
		color: (
			primary: $default-dark-primary,
			accent: $default-dark-accent,
			warn: $default-dark-warn,
		),
		typography: $mat-default-typography,
	)
);

.theme-default-dark {
	// Create an Angular Material theme from the $theme map
	@include mat.all-component-themes($mat-default-theme);
	@include matcha.matcha-components($matcha-default-theme);
	@include matcha.matcha-typography($matcha-default-typography);
}
