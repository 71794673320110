/* You can add global styles to this file, and also import other style files */

@import 'app/app.theme.scss';

body {
	margin: 0;
}

.mat-horizontal-stepper-header-container {
	max-width: 75.7%;
	margin-top: -20px;
	background-color: white;
	padding: 20px;
	margin-bottom: 20px;
	margin-left: 11%;
	display: flex !important;
	justify-content: center !important;
	border-radius: 8px;
	z-index: 9999;
}

.mat-step-icon-state-done {
	background-color: #0f883f !important;
}
.mat-step-icon-state-edit {
	background-color: #223744 !important;
}

.fix {
	position: fixed;
  	top: 0;
	width: 290px;
}

.snack-error {

	.mdc-snackbar__surface {
		background-color: red !important;
	}

	.mat-mdc-snack-bar-label {
		font-size: 22px !important;
	}

	.mdc-button__label {
		font-size: 16px !important;
		color: #fff;
	}
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
	pointer-events: none;
}
